<template>
    <v-dialog max-width="450" persistent v-model="dialog">
        <v-card>
            <v-card-title class="headline">{{ $t('msg.update_application_details') }}</v-card-title>
            <v-card-text>
                <ValidationObserver ref="observer" v-slot="{failedRules, failed ,passes}">
                    <form>
                        <ValidationProvider  vid="applicationNumber" name="applicationNumber"
                                             :rules="{required: application.applicationStatus === 'A', numeric:true, max:10 }" ref="observerEditApplicationNumber"
                                            v-slot="{errors, failedRules}">
                            <v-text-field
                                    :error-messages="$translatedErrorMessages(failedRules,'applicationNumber')"
                                    :label="$t('lbl.application_number')"
                                    :class="{'requiredSticker': application.applicationStatus === 'A'}"
                                    v-model="application.applicationNumber">
                            </v-text-field>
                        </ValidationProvider>

                        <ValidationProvider rules="required" v-slot="{errors, failedRules}">
                            <v-select
                                    :error-messages="$translatedErrorMessages(failedRules,'customerApplicationStatus')"
                                    :items="$parent.filters.applicationStatus"
                                    @change="onStatusChanged"
                                    item-text="text"
                                    item-value="value"
                                    :label="$t('lbl.application_status')"
                                    v-model="application.applicationStatus"
                            >
                            </v-select>
                        </ValidationProvider>

                        <div v-if="application.applicationStatus === 'A'">
                            <ValidationProvider
                                    :rules="{numeric:true, required: true, min_value: validation.minAmount, max_value: validation.maxAmount }"
                                    v-slot="{errors, failedRules}">
                                <v-text-field
                                        :error-messages="$translatedErrorMessages(failedRules,'financingAmountApproved', [currency, validation.minAmount, validation.maxAmount])"
                                        :label="$t('lbl.financing_amount_approved')"
                                        :prefix="currency"
                                        required
                                        v-model="application.financingAmountApproved">
                                </v-text-field>
                            </ValidationProvider>

                            <ValidationProvider
                                :rules="{numeric:true, required: true, min_value: validation.minTenure, max_value: validation.maxTenure }"
                                v-slot="{errors, failedRules}">
                              <v-text-field
                                  :error-messages="$translatedErrorMessages(failedRules,'tenureApproved', [validation.minTenure, validation.maxTenure])"
                                  :label="$t('lbl.financing_tenure_approved')"
                                  suffix="Months"
                                  required
                                  v-model="application.tenureApproved">
                              </v-text-field>
                            </ValidationProvider>
                        </div>

                        <ValidationProvider :rules="{required: false, min : 1, max : 3, regex:/^[a-zA-Z0-9\s-+#@]+$/ }"
                                            v-slot="{errors, failedRules}">
                          <v-text-field :error-messages="$translatedErrorMessages(failedRules,'internalRating')"
                                        label="Internal Credit Rating" v-model="application.internalRating"></v-text-field>
                        </ValidationProvider>
                    </form>
                    <div>
                        <div v-show="saveError">
                          <p style="color:red" >{{ $t('msg.failed_action') }}</p>
                        </div>
                        <div v-show="serverError">
                            <p style="color:red" >{{ $t('error.server') }}</p>
                        </div>
                    </div>
                    <v-card-actions align="right">
                        <v-spacer></v-spacer>
                        <v-skeleton-loader class="mr-3" :loading="loader" transition="scale-transition" type="button">
                            <v-btn @click="cancel" color="primary">{{ $t('cancel') }}</v-btn>
                        </v-skeleton-loader>
                        <v-skeleton-loader :loading="loader" transition="scale-transition" type="button">
                            <v-btn @click="passes(updateApplicationStatus)" color="primary" dark>
                              {{ $t('save') }}
                            </v-btn>
                        </v-skeleton-loader>
                    </v-card-actions>
                </ValidationObserver>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {ValidationObserver, ValidationProvider} from 'vee-validate';
    import {dataServices} from "@/services";


    export default {
        components: {
            ValidationObserver, ValidationProvider
        },
        data() {
            return {
                saveError: false,
                dialog: false,
                application: {},
                loader: false,
                validation: {
                    minAmount: 1,
                    maxAmount: 10000,
                    minTenure: 1,
                    maxTenure: 24
                },
                internalRatingAvailableItems: [
                    {text: 'Yes', value: 'Y'},
                    {text: 'No', value: 'N'}
                ],
                ctosRatingAvailableItems: [
                    {text: 'Yes', value: 'Y'},
                    { text: 'No', value: 'N'}
                ],
                ccrisRatingAvailableItems: [
                    {text: 'Yes', value: 'Y'},
                    {text: 'No', value: 'N'}
                ],
                serverError: false,
                currency: 'T$',
            }
        },
        methods: {
            init() {
                this.application = Object.assign({}, this.$parent.selectedApplication)
                this.dialog = true
                /*let productValidation = this.$parent.filters.scheme.filter(f => f.code === this.application.productApplied)[0].validation
                console.log(productValidation)
                if(productValidation)
                   this.validation=productValidation*/
            },
            cancel() {
              this.dialog = false
              this.saveError = false
            },
            updateApplicationStatus() {
                this.loader = true
                dataServices.editApplicationStatus(this.application).then((response) => {
                  this.loader = false
                  if (response.successful) {
                    this.$parent.renderMessage('Successfully updated the application details')
                    this.dialog = false
                    this.loader = false
                  } else {
                    response.errorList.forEach(e => {
                      if(e.field==='applicationNumber' && e.rule==='duplicate'){
                        this.$refs.observerEditApplicationNumber.applyResult({
                          errors: ['error'],
                          failedRules: ['duplicate']
                        });
                      }
                    })
                  }
                }).catch((error) => {
                  this.loader = false
                  this.serverError = true
                  console.log('Error' + error)
                })
              }
        }
    }
</script>

<style>
.requiredSticker label::after {
  content: "*";
  color: red;
}
.notes {
  padding-left: 27px;
  padding-bottom: 10px;
  margin-top: -15px;
  font-size: 12px;
  color: grey;
}
</style>
