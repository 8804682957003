<template>
  <div>
    <v-container class="mt-lg-10" fluid>
      <v-row dense>
        <v-col>
          <v-card>
            <v-app-bar color="primary" dark>
              <v-icon class="mr-3">mdi-filter</v-icon>
              <v-toolbar-title>{{ $t('msg.filters_title') }}</v-toolbar-title>
            </v-app-bar>
            <v-container fluid>
              <v-row dense>
                <v-col :key="idx" cols="12" lg="3" md="3" v-for="(value, key, idx) in filters">
                  <v-skeleton-loader :loading="loadingFilterData" transition="scale-transition"
                                     type="card-heading">
                    <v-select :items="value" :label="$t(`msg.${key}_label`)" chips deletable-chips
                              dense item-text="label" item-value="code" outlined small-chips class="contain-long-inputs"
                              v-if="key === 'scheme'" v-model="filterCriteria[`${key}`]"></v-select>

                    <v-select :items="value" :label="$t(`msg.${key}_label`)" chips
                              deletable-chips dense outlined small-chips class="contain-long-inputs"
                              v-else v-model="filterCriteria[`${key}`]"></v-select>
                  </v-skeleton-loader>
                </v-col>
                <v-col class="col-12 col-lg-3 col-md-3">
                  <v-skeleton-loader :loading="loadingFilterData" transition="scale-transition"
                                     type="card-heading">
                    <v-menu :close-on-content-click="false" :nudge-right="40" min-width="290px"
                            offset-y transition="scale-transition" v-model="startDate">
                      <template v-slot:activator="{ on }">
                        <v-text-field :label="$t('msg.start_date')" append-icon="event"
                                      clearable
                                      dense outlined readonly v-on:change="startDateChanged"
                                      v-model="filterCriteria.startDate"
                                      v-on="on"></v-text-field>
                      </template>
                      <v-date-picker @input="startDate = false" :max="dateEndLimit1" v-on:change="startDateChanged"
                                     v-model="filterCriteria.startDate"></v-date-picker>
                    </v-menu>
                  </v-skeleton-loader>
                </v-col>
                <v-col class="col-12 col-lg-3 col-md-3">
                  <v-skeleton-loader :loading="loadingFilterData" transition="scale-transition"
                                     type="card-heading">
                    <v-menu :close-on-content-click="false" :nudge-right="40" min-width="290px"
                            offset-y transition="scale-transition" v-model="endDate">
                      <template v-slot:activator="{ on }">
                        <v-text-field :label="$t('msg.end_date')" append-icon="event" clearable
                                      dense outlined readonly @click:clear="endDateChanged"
                                      v-model="filterCriteria.endDate" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker @input="endDate = false" :min="dateStartLimit2" :max="dateEndLimit2"
                                     v-on:change="endDateChanged"
                                     v-model="filterCriteria.endDate"></v-date-picker>
                    </v-menu>
                  </v-skeleton-loader>
                </v-col>
                <!--                                <v-col class="col-12 col-lg-3 col-md-3"></v-col>-->
                <v-col class="d-flex justify-lg-end justify-md-end justify-center">
                  <v-skeleton-loader :loading="loadingFilterData" transition="scale-transition"
                                     type="button" class="mr-3">
                    <v-btn @click="resetFilters" color="primary" dark>{{ $t('reset') }}</v-btn>
                  </v-skeleton-loader>
                  <v-skeleton-loader :loading="loadingFilterData" transition="scale-transition"
                                     type="button">
                    <v-btn @click="filterData" color="primary" dark>{{ $t('filter') }}</v-btn>
                  </v-skeleton-loader>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="customersTablePadding" fluid>
      <v-row>
        <v-col>
          <v-card>
            <v-app-bar color="primary" dark>
              <v-icon class="mr-3">mdi-account-group</v-icon>
              <v-toolbar-title class="responsive-title">
                <span v-if="$route.params.userType !== undefined &&
                $store.state.home.user.products.length > 1">
                  {{ $route.params.userType.toUpperCase() }}
                </span>
                {{ $t('msg.customers') }}
              </v-toolbar-title>
              <v-row justify="end">
                <v-dialog max-width="450" persistent v-model="appliedDialog">
                  <template v-slot:activator="{ on }">
                    <v-btn class="pad-filter-button" color="primary" dark v-on="on">{{ $t('msg.view_applied_filters') }}</v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline">{{ $t('msg.filters_applied') }}</v-card-title>
                    <v-card-text>
                      <v-simple-table>
                        <template v-slot:default>
                          <tbody>
                          <tr :key="idx" v-for="(value, key, idx) in filters">
                            <td>{{ $t(`msg.${key}_label`) }}</td>
                            <td>:</td>
                            <td v-if="undefined == appliedFilters[key]">
                              {{ $t(`msg.${key}_all_applied`) }}
                            </td>
                            <td v-else-if="key === 'scheme'">
                              {{ value.filter(f => f.code === appliedFilters[key])[0].label }}
                            </td>
                            <td v-else>
                              {{ value.filter(f => f.value === appliedFilters[key])[0].text }}
                            </td>
                          </tr>
                          <tr>
                            <td>{{ $t('msg.start_date') }}</td>
                            <td>:</td>
                            <td v-if="appliedFilters.startDate">
                              {{ appliedFilters.startDate }}
                            </td>
                            <td v-else>
                              {{ $t('msg.all_dates') }}
                            </td>
                          </tr>
                          <tr>
                            <td>{{ $t('msg.end_date') }}</td>
                            <td>:</td>
                            <td v-if="appliedFilters.endDate">
                              {{ appliedFilters.endDate }}
                            </td>
                            <td v-else>
                              {{ $t('msg.all_dates') }}
                            </td>
                          </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn @click="appliedDialog = false" color="primary" dark>
                        {{ $t('msg.close_label') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
            </v-app-bar>
            <v-container fluid class="containerStyling">
              <v-row class="d-flex justify-center">
                <span :key="index" v-for="(header, index) in headers">
                  <v-col
                      v-if="header.toggle &&
                      (undefined === header.roles || header.roles.some(r => $store.state.home.user.roles.includes(r)))">
                    <v-checkbox :label="header.text" color="primary" v-model="header.selectedCheckbox" v-on:change="recordTicks()"/>
                  </v-col>
                </span>
              </v-row>
            </v-container>
            <v-card>
              <v-skeleton-loader :loading="tableDataLoading" transition="scale-transition"
                                 type="table-tbody">
                <v-card-title>
                  <v-row>
                    <v-col md="7"></v-col>
                    <v-text-field hide-details placeholder="Search" single-line
                                  v-model="filterCriteria.searchText"></v-text-field>
                    <div class="pl-3 mt-2"></div>
                    <v-btn @click="filterData" class="ma-2" color="primary">
                      <v-icon dark>mdi-magnify</v-icon>
                    </v-btn>
                  </v-row>
                </v-card-title>
              </v-skeleton-loader>
              <v-data-table :disable-sort="true"
                            :footer-props="{
                              'items-per-page-options': pagination.itemPerPageOptions,
                              'show-current-page':true
                            }"
                            :headers="filteredHeaders" :items="table.tableData"
                            :options.sync="pagination" :server-items-length=table.totalLength
                            @update:options="paginate"
                            v-show="!tableDataLoading">

                <template v-slot:item.applicationNumber="{ item }">
                  <span v-if="item.applicationNumber">
                    {{ item.applicationNumber }}
                  </span>
                  <span class="text-center" v-else>
                    <v-icon>$vuetify.icons.values.mdiMinus</v-icon>
                  </span>
                </template>

                <template v-slot:item.businessRegistrationNumber="{ item }">
                  <span v-if="item.businessRegistrationNumber">
                    {{ item.businessRegistrationNumber }}
                  </span>
                  <span class="text-center" v-else>
                    <v-icon>$vuetify.icons.values.mdiMinus</v-icon>
                  </span>
                </template>

                <template v-slot:item.companyName="{ item }">
                  <span v-if="item.companyName">
                    {{ item.companyName }}
                  </span>
                  <span class="text-center" v-else>
                    <v-icon>$vuetify.icons.values.mdiMinus</v-icon>
                  </span>
                </template>

                <template v-slot:item.gfi_completion_date="{ item }">
                  <span v-if="item.completionDateString">
                    {{ item.completionDateString }}
                  </span>
                  <span class="text-center" v-else>
                    <v-icon>$vuetify.icons.values.mdiMinus</v-icon>
                  </span>
                </template>

                <template v-slot:item.details="{ item }">
                  <v-icon v-if="item.status==='FINISHED'" @click="editItem(item, true)" class="mr-2" color="primary darken-2" small>
                    mdi-square-edit-outline
                  </v-icon>
                  <v-icon class="mr-2" color="grey darken-2" small v-else>
                    mdi-square-edit-outline
                  </v-icon>
                </template>

                <template v-slot:item.scoring>
                  <v-icon class="mr-2" color="grey darken-2" small >
                    mdi-calculator
                  </v-icon>
                </template>
                <template v-slot:item.scoring="{ item }">
                  <v-icon @click="generateScore(item)" class="mr-2" color="blue darken-2" small
                          v-if="item.readyForScoring && !item.scoringTriggeredByOfficer && item.gfiScoreGenerationCount<1">
                    mdi-calculator
                  </v-icon>
                  <v-icon class="mr-2" color="grey darken-2" small
                          v-if="(!item.readyForScoring) || (item.gfiScoreGenerationCount>1) || (item.readyForScoring && item.scoringTriggeredByOfficer)">
                    mdi-calculator
                  </v-icon>
                                </template>

<!--                <template v-slot:item.scoring>-->
<!--                  <v-icon class="mr-2" color="grey darken-2" small >-->
<!--                    mdi-calculator-->
<!--                  </v-icon>-->
<!--                </template>-->

<!--                <template v-slot:item.applicationStatus="{ item }">-->
<!--                  {{ item.applicationStatusLabel }}-->
<!--                  <span>-->
<!--                    <v-icon class="mr-2" color="grey darken-2" small>-->
<!--                      mdi-square-edit-outline-->
<!--                    </v-icon>-->
<!--                  </span>-->
<!--                </template>-->

                <template v-slot:item.applicationStatus="{ item }">
                  {{ item.applicationStatusLabel }}
                  <span>
                    <v-icon @click="editApplicationStatus(item)" class="mr-2" color="primary" small
                            v-if="['ROLE_HQ_OFFICER'].some(r => $store.state.home.user.roles.includes(r))">
                      mdi-square-edit-outline
                    </v-icon>
                  </span>
                </template>

                <template v-slot:item.testTakerScoreOverallRating="{ item }">
                  <span v-if="item.hasGfiScore">
                    {{ item.testTakerScoreOverallRating }}
                  </span>
                  <span class="text-center" v-else>
                    <v-icon>$vuetify.icons.values.mdiMinus</v-icon>
                  </span>
                </template>

                <template v-slot:item.gfiRating="{ item }">
                  <span v-if="item.hasGfiScore">
                    {{ item.gfiRating }}
                  </span>
                  <span class="text-center" v-else>
                    <v-icon>$vuetify.icons.values.mdiMinus</v-icon>
                  </span>
                </template>

                <template v-slot:item.report="{item}">
                  <span v-show="!isFinancialAdvisoryOfficer">
                    <span v-if="item.hasGfiScore">
                      <span v-if="checkArray(item.id,item.idNumber,'BANK_REPORT')">
                        <v-icon
                            @click.native="downloadReport(item.id,item.idNumber,'BANK_REPORT')"
                            color="blue">mdi-file-download</v-icon>
                      </span>
                      <span v-else>
                        <v-progress-circular :size="20" color="primary" indeterminate />
                      </span>
                    </span>
                    <span v-else>
                      <v-icon>mdi-file-download</v-icon>
                    </span>
                  </span>
                </template>

                <template v-slot:item.report_ent>
                  <span v-show="!isFinancialAdvisoryOfficer">
                    <span>
                      <v-icon>mdi-file-download</v-icon>
                    </span>
                  </span>
                </template>

                <template v-slot:item.report_ai>
                  <span v-show="!isFinancialAdvisoryOfficer">
                    <span>
                      <v-icon disabled>mdi-eye-outline</v-icon>
                    </span>
                  </span>
                </template>

                <template v-slot:item.report_fin>
                  <span v-show="!isFinancialAdvisoryOfficer">
                    <span>
                      <v-icon>mdi-file-download</v-icon>
                    </span>
                  </span>
                </template>

              </v-data-table>

              <div v-if="isFinancialAdvisoryOfficer">
                <v-card class="dashboard-datatable-footer dashboardFooterBottomMargin">
                  <v-card-actions class="dashboard-datatable-footer-actions">
                    <p>
                      <v-icon class="px-2" x-small>mdi-checkbox-blank-circle</v-icon>
                      {{ $t('msg.f1_text') }}
                    </p>
                    <p>
                      <v-icon class="px-2" x-small>mdi-checkbox-blank-circle</v-icon>
                      {{ $t('msg.f2_text') }}
                    </p>
                    <p>
                      <v-icon class="px-2" x-small>mdi-checkbox-blank-circle</v-icon>
                      {{ $t('msg.f3_text') }}
                    </p>
                  </v-card-actions>
                </v-card>
              </div>
              <div v-if="isShowExportButtonEnabled">
                <v-skeleton-loader :loading="tableDataLoading" transition="scale-transition"
                                   type="card-heading">
                  <v-card class="dashboard-datatable-footer dashboardFooterBottomMargin">
                    <v-card-actions class="dashboard-datatable-footer-actions">
                      <v-btn :disabled="exporting" @click="exportData" class="ma-2 primary" >
                        <v-progress-circular :size="20" color="primary" indeterminate
                                             v-show="exporting">
                        </v-progress-circular>
                        <span v-show="exporting">&nbsp;</span>{{$t('msg.export_button')}}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-skeleton-loader>
              </div>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
      <CustomerMaintenance ref="customerMaintenanceWidget"/>
      <UpdateApplicationStatus ref="applicationStatusUpdateWidget"/>
      <ScoreGenerationConfirmationWidget ref="generateScoreWidget"/>
    </v-container>
    <v-snackbar
        :right="'right'"
        :timeout=4000
        :top="'top'"
        color="success"
        v-model="alert">
      {{ message }}
    </v-snackbar>
  </div>
</template>

<script>
import {dataServices, downloadFileService} from "../../../services";

import debounce from 'lodash/debounce'
import fileSaver from 'file-saver'
import CustomerMaintenance from "./components/CustomerMaintainence";
import UpdateApplicationStatus from "./components/UpdateApplicationStatus";
import ScoreGenerationConfirmationWidget from "./components/ScoreGenerationConfirmationWidget";

export default {
  name: 'UserList',
  components: {
    CustomerMaintenance,
    UpdateApplicationStatus,
    ScoreGenerationConfirmationWidget
  },
  updated() {
    if (this.userType !== this.$route.params.userType) {
      this.userType = this.$route.params.userType
      this.init()
    }
  },
  data() {
    return {
      reportDownloadError: false,
      exporting: false,
      exportDataDownloadError: false,
      hideColumn: false,
      loadingFilterData: true,
      tableDataLoading: true,
      loading: false,
      selectedSchedule: '',
      appliedFilters: {},
      selectedApplication: {},
      table: {
        tableData: [],
        totalLength: 0
      },
      userDropDowns: {},
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      startDate: false,
      endDate: false,
      appliedDialog: false,
      alert: false,
      message: '',
      requestDataArray: [''],
      scoreGenerated: false,
      dateEndLimit1: new Date().toJSON(),
      dateStartLimit2: null,
      dateEndLimit2: new Date().toJSON(),
      items: [
        {text: 'Home', disabled: true, href: 'breadcrumbs_dashboard'},
        {text: 'Manage', disabled: true, href: 'breadcrumbs_link_1'}
      ],
      headers: [
        {position: 1, text: this.$t('msg.sl_no'), align: 'center', selectedCheckbox: true, value: 'slno'},
        {
          position: 2,
          text: this.$t('msg.application_number'),
          align: 'center',
          value: 'applicationNumber',
          selectedCheckbox: true,
          toggle: false
        },
        {
          position: 3,
          text: this.$t('msg.br_number'),
          align: 'center',
          value: 'businessRegistrationNumber'
        },
        {
          position: 4,
          text: this.$t('msg.company_name'),
          align: 'center',
          toggle: true,
          selectedCheckbox: false,
          value: 'companyName'
        },
        {
          position: 5,
          text: this.$t('msg.id_number'),
          align: 'center',
          toggle: true,
          selectedCheckbox: false,
          value: 'idNumber',
        },
        {
          position: 6,
          text: this.$t('msg.id_number_type'),
          align: 'center',
          toggle: true,
          selectedCheckbox: false,
          value: 'idNumberTypeLabel',
        },
        {
          position: 7,
          text: this.$t('msg.customer_name'),
          align: 'center',
          value: 'fullname'
        },
        {
          position: 8,
          text: this.$t('msg.verification_code'),
          align: 'center',
          value: 'verificationCode'
        },
        {
          position: 9,
          text: this.$t('msg.branch'),
          align: 'center',
          toggle: true,
          selectedCheckbox: false,
          value: 'branch',
          roles: ['ROLE_SUPERVISOR', 'ROLE_HQ_OFFICER']
        },
        {
          position: 10,
          text: this.$t('msg.scheme'),
          align: 'center',
          value: 'productAppliedLabel',
        },
        {
          position: 11,
          text: this.$t('msg.financing_amount_requested'),
          align: 'center',
          value: 'loanAmount'
        },
        {
          position: 12,
          text: this.$t('msg.tenure_applied'),
          align: 'center',
          value: 'tenureApplied'
        },
        {
          position: 13,
          text: this.$t('msg.gfi_status'),
          align: 'center',
          value: 'completionStatus'
        },
        {
          position: 14,
          text: this.$t('msg.gfi_registration_date'),
          align: 'center',
          toggle: true,
          selectedCheckbox: false,
          value: 'registrationDateString'
        },
        {
          position: 15,
          text: this.$t('msg.gfi_completion_date'),
          align: 'center',
          value: 'gfi_completion_date'
        },
        {
          position: 16,
          text: this.$t('msg.expiry_date'),
          align: 'center',
          toggle: true,
          selectedCheckbox: false,
          value: 'expiryDate'
        },
        {
          position: 17,
          text: this.$t('msg.update_details'),
          align: 'center',
          value: 'details',
          roles: ['ROLE_LOAN_OFFICER', 'ROLE_HQ_OFFICER']
        },
        {
          position: 18,
          text: this.$t('msg.gfi_scoring'),
          align: 'center',
          value: 'scoring',
          roles: ['ROLE_LOAN_OFFICER','ROLE_HQ_OFFICER']
        },
        {
          position: 19,
          text: this.$t('msg.gfi_credit_score'),
          align: 'center',
          value: 'testTakerScoreOverallRating',
          roles: ['ROLE_LOAN_OFFICER', 'ROLE_SUPERVISOR', 'ROLE_HQ_OFFICER']
        },
        {
          position: 20,
          text: this.$t('msg.gfi_credit_rating'),
          align: 'center',
          value: 'gfiRating',
          roles: ['ROLE_LOAN_OFFICER', 'ROLE_SUPERVISOR', 'ROLE_HQ_OFFICER']
        },
        {
          position: 21,
          text: this.$t('msg.download_report'),
          align: 'center',
          value: 'report',
          roles: ['ROLE_LOAN_OFFICER', 'ROLE_SUPERVISOR', 'ROLE_HQ_OFFICER']
        },
        {
          position: 22,
          text: this.$t('msg.download_financial_report'),
          align: 'center',
          toggle: true,
          selectedCheckbox: false,
          value: 'report_fin',
          roles: ['ROLE_LOAN_OFFICER', 'ROLE_SUPERVISOR', 'ROLE_HQ_OFFICER']
        },
        {
          position: 23,
          text: this.$t('msg.download_ent_report'),
          align: 'center',
          toggle: true,
          selectedCheckbox: false,
          value: 'report_ent',
          roles: ['ROLE_LOAN_OFFICER', 'ROLE_SUPERVISOR', 'ROLE_HQ_OFFICER']
        },
        {
          position: 24,
          text: this.$t('msg.ai_proctoring_report'),
          align: 'center',
          toggle: true,
          selectedCheckbox: false,
          value: 'report_ai',
          roles: ['ROLE_LOAN_OFFICER', 'ROLE_SUPERVISOR', 'ROLE_HQ_OFFICER']
        },
        {
          position: 25,
          text: this.$t('msg.f1'),
          align: 'center',
          value: 'compliance',
          roles: ['ROLE_FA_OFFICER']
        },
        {
          position: 26,
          text: this.$t('msg.f2'),
          align: 'center',
          value: 'entrepreneurship',
          roles: ['ROLE_FA_OFFICER']
        },
        {
          position: 27,
          text: this.$t('msg.f3'),
          align: 'center',
          value: 'financialDecisionMaking',
          roles: ['ROLE_FA_OFFICER']
        },
        {
          position: 28,
          text: this.$t('msg.application_status'),
          align: 'center',
          value: 'applicationStatus',
        }
      ],
      checkItemArray: [],
      pagination: {
        itemsPerPage: 10,
        itemPerPageOptions: [10]
      },
      filterCriteria: {},
      filters: {},
      userType: '',
      tempBranches: []
    }
  },
  methods: {
    prepareDataForRequest: function () {
      let request = Object.assign({}, this.appliedFilters)
      request.pageSize = this.pagination.itemsPerPage
      request.pageNumber = this.pagination.page
      request.financeType = this.$route.params.userType.toUpperCase()
      return request
    },
    getDataFromApi: function () {
      let data = this.prepareDataForRequest()
      dataServices.getDataForCustomers(data).then(response => {
        console.log(response)
        this.table.tableData = response.dataResponse.resultData.table_content
        this.table.totalLength = response.dataResponse.resultData.total_rows
        this.tableDataLoading = false
      })
    },
    getDropDownData: function () {
      dataServices.getDataForFilters(this.$route.params.userType.toUpperCase()).then(r => {
        this.filters = r.dataResponse
        this.loadingFilterData = false
      })
    },
    editItem: function (item, validation) {
      console.log(item)
      this.$refs.customerMaintenanceWidget.init(item, validation)
    },
    editApplicationStatus: function (item) {
      this.selectedApplication = item
      this.$refs.applicationStatusUpdateWidget.init()
    },
    filterData: function () {
      this.appliedFilters = Object.assign({}, this.filterCriteria)
      this.pagination.page = 1
      this.paginate()
    },
    resetFilters: function () {
      this.appliedFilters = {}
      this.filterCriteria = {}
      this.pagination.page = 1
      this.paginate()
      this.tempBranches = this.filters.branch
    },
    paginate() {
      if (this.userType.length > 0) {
        this.tableDataLoading = true
        this.getDataFromApi()
      }
    },
    generateScore: function (item) {
      this.selectedApplication = item
      this.$refs.generateScoreWidget.init()
    },
    exportData: function () {
      if (this.exporting) {
        return false
      }
      this.exportDataDownloadError = false
      this.exporting = true
      let data = this.prepareDataForRequest()
      downloadFileService.downloadExcel(data)
          .then(response => {
            if (response) {
              fileSaver.saveAs(response, 'excel_export.xlsx')
              this.exporting = false
            }
          }).catch(error => {
        this.exporting = false
        this.exportDataDownloadError = true
        /* eslint-disable no-console */
        console.log('loggin response ' + error)
        /* eslint-enable no-console */
      })
    },
    downloadReport: function (ttid, idNumber, reportType) {
      this.reportDownloadError = false
      let loaderString = ttid + '-' + idNumber + '-' + reportType
      if (this.requestDataArray.indexOf(loaderString) < 0) {
        this.requestDataArray.push(loaderString)
      }
      downloadFileService.downloadReport({id: ttid, type: this.userType})
          .then(response => {
            if (response) {
              fileSaver.saveAs(response, idNumber + '.pdf')
            }
            this.requestDataArray.splice(this.requestDataArray.indexOf(loaderString), 1)
            this.loading = false
          }).catch(() => {
        this.loading = false
        this.requestDataArray.splice(this.requestDataArray.indexOf(loaderString), 1)
        this.reportDownloadError = true
      })
    },
    checkArray: function (ttid, idNumber, reportType) {
      return this.requestDataArray.indexOf(ttid + '-' + idNumber + '-' + reportType) < 0
    },
    recordTicks: function () {
      let selectedColumns = this.headers
          .filter(header => (header.toggle && header.selectedCheckbox))
          .map(function (header) {
            return header.position
          })
      this.$cookies.set('gfi-package-' + this.userType, JSON.stringify(selectedColumns))
    }, init() {
      this.getDropDownData()
      this.resetFilters()
      // this.paginate()
      if (this.$cookies.get('gfi-package-' + this.userType)) {
        let selectedColumns = JSON.parse(this.$cookies.get('gfi-package-' + this.userType))
        for (let i = 0; i < this.headers.length; i++) {
          if (selectedColumns.includes(this.headers[i].position)) {
            this.headers[i].selectedCheckbox = true
          }
        }
      }
    },
    renderMessage(message, paginate) {
      this.alert = true
      this.message = message
      if (undefined === paginate || paginate) {
        this.paginate()
      }
    },
    removeApplicationIndex(applicationId) {
      let idx = this.checkItemArray.indexOf(applicationId)
      this.checkItemArray.splice(idx, 1)
    },
    startDateChanged(date) {
      if (date && typeof date === 'string') {
        let parts = date.split('-');
        let newDate = new Date();
        newDate.setDate(parts[2])
        newDate.setMonth(parts[1] - 1)
        newDate.setFullYear(parts[0])
        this.dateStartLimit2 = newDate.toJSON();
        console.log('start date' + date)
      } else {
        this.dateStartLimit2 = null
      }
    },
    endDateChanged(date) {
      console.log(typeof date);

      if (date && typeof date === 'string') {
        let parts = date.split('-');
        let newDate = new Date();
        newDate.setDate(parts[2])
        newDate.setMonth(parts[1] - 1)
        newDate.setFullYear(parts[0])
        this.dateEndLimit1 = newDate.toJSON();
      } else {
        this.dateEndLimit1 = new Date().toJSON();
      }
      console.log('end date' + date)
    }
  },
  computed: {
    filteredHeaders: function () {
      let roles = this.$store.state.home.user.roles
      let columnsForRole = this.headers.filter(function (e) {
        if (!e.roles || e.roles.length === 0) {
          return true
        } else if (e.roles.some(item => roles.includes(item))) {
          return true
        } else {
          return false
        }
      });
      return columnsForRole.filter(h => undefined === h.selectedCheckbox || h.selectedCheckbox)
    },
    isShowExportButtonEnabled: function () {
      return (this.$store.state.home.user.roles.some(role => ['FI_OFFICER', 'ROLE_HQ_OFFICER'].includes(role)) && this.$store.state.home.user.isExportButtonEnabled)
    },
    isFinancialAdvisoryOfficer: function () {
      return this.$store.state.home.user.roles.includes('ROLE_FA_OFFICER')
    },
    isLoanOfficer: function () {
      return this.$store.state.home.user.roles.includes('ROLE_HQ_OFFICER')
    },
    isHQOfficer: function () {
      return this.$store.state.home.user.roles.includes('ROLE_HQ_OFFICER')
    }
  },
  watch: {
    'filterCriteria.searchText': debounce(function (newValue) {
      if (newValue === '') {
        this.getDataFromApi()
      }
    }, 900)
  },
}
</script>

<style>
.containerStyling {
  margin-top: 0px;
  margin-bottom: 0px;
}

.customersTablePadding {
  padding-top: 0px;
  padding-bottom: 100px;
}

.v-select--chips.contain-long-inputs .v-select__selections input {
  display: none;
}

@media only screen and (max-width: 390px) {
  .responsive-title {
    display: none;
  }

  .pad-filter-button {
    margin-right: 10px;
  }
}
</style>
